<template>
  <el-main>
    <el-row class="body_img"></el-row>

    <el-row type="flex" justify="center">
      <el-col :span="17" class="bkg">
        <el-result icon="success">
          <template slot="title">
            <h1 class="big_title_fun">正在下载...</h1>
          </template>
          <template slot="subTitle">
            <span
              >五秒内无反应，请<a @click="download()">点击这里</a
              >尝试下载。</span
            >
          </template>
        </el-result>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
export default {
  name: "download",
  methods: {
    download() {
      window.location.href = "/api/updown/download?dfile=" + this.$route.query.file
    },
  },
  mounted() {
      setTimeout(() => {
          console.log("123")
          window.location.href = "/api/updown/download?dfile=" + this.$route.query.file
      }, 500);
  },
};
</script>

<style scoped>
.el-main {
  background: #f1f3fe;
  color: #333;
  margin-top: 72px;
}

.body_img {
  height: 130px;
  background: url("~@/assets/content.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

.bkg {
  color: #5a5b64;
  text-align: center;
  margin-top: -4px;
  height: 800px;
  background: white;
  border-radius: 20px;
}

a {
  color: rgb(64, 158, 255);
}

a:hover,
a:link,
a:visited,
a:active,
a:focus {
  cursor: pointer;
  text-decoration: none;
  outline: none;
}
</style>